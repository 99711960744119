import { Component, HostListener, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  InteractionStatus,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { env } from '../config/env';
import { environments } from '../config/env.config';
import { Router } from '@angular/router';
import { HeaderLink } from '@de-electron/electron-angular-components';
import { DeHeaderModule } from '@de-electron/electron-angular-components';
import { PageHeaderService } from './services/pageheader';

@Component({
  selector: 'disis-admin-ui-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /***
   * This class is out of the box functionality from Microsoft MSAL angular SPA samples (Angular v12)
   * It provides login/logout via redirect as well as popups.  Popups generally don't work in MS Edge.
   *  */

  title = 'DISIS Heatmap';
  sidenavToggleCheck = true;
  pageHeader : string = 'Upload Study Files';
  isIframe = false;
  loginDisplay = false;
  loggedInheaderLinks = [{
    value: 'string',showUserIcon: true,subLinks: [{viewValue: 'Profile',value: 'profile'},{viewValue: 'Logout',value: 'logout'}]}]
  loggedOutheaderLinks = [{
    value: 'login',viewValue: 'login'}]

  path: string; // Added to support path based *ngIf's in top level app component html

  targetEnv = environments[env.env].environment?environments[env.env].environment:'Local';

  private readonly destroying = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private pageHeaderService: PageHeaderService
  ) {
    pageHeaderService.updatePageHeader.subscribe((data: string) => {
      this.pageHeader = data;
    });
    pageHeaderService.updateSidenavCheck.subscribe((data: boolean) => {
      this.sidenavToggleCheck  = data;
    });
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (window.location.pathname === '/' && this.loginDisplay){
      this.router.navigate(['/'])
    }
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect(): void {
    this.router.navigate(['/'])
  }

  logout(): void {
    this.authService.logoutRedirect();
  }

  handleLinkClick(event:any){
    console.log(event);
    switch (event.value) {
      case "profile":
        this.router.navigate(['profile'])
        break;
      case "logout":
        this.logout()
        break;
      case "login":
        this.loginRedirect()
        break;
      default:
        break;
    }

  }

  handleNavigation(val: HeaderLink){
      this.router.navigate(['/']);
  }

  toggleAction(){
    this.sidenavToggleCheck = !this.sidenavToggleCheck;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  checkWindowSize() {
    if (window.innerWidth < 768) {
      this.sidenavToggleCheck = false; // Collapse sidenav
    }
    if (window.innerWidth > 768) {
      this.sidenavToggleCheck = true; // Expand sidenav
    }
  }

  ngOnDestroy(): void {
    this.destroying.next(undefined);
    this.destroying.complete();
  }
}
